import React, { useState, useRef } from 'react';

import { CSVReader } from 'react-papaparse'
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";



function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
        // BOM sequence
        str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
}

// Get paragraphs as javascript array
function getParagraphs(content, testSeriesId) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const tags = xml.getElementsByTagName("w:tbl")
    const allQuestions = []
    for (let i = 0; i < tags?.length; i++) {
        let makeQuestion = {}
        const rows = tags[i].getElementsByTagName("w:tr")
        if (rows.length < 8) {
            continue;
        }
        for (let j = 0; j < rows.length; j++) {
            let correct = ""
            let question = rows[j]?.getElementsByTagName("w:tc")[0]?.getElementsByTagName("w:p")[0]
                .getElementsByTagName("w:t")[0]?.childNodes[0].nodeValue
            let value = rows[j]?.getElementsByTagName("w:tc")[1]?.getElementsByTagName("w:p")[0]
                .getElementsByTagName("w:t")[0]?.childNodes[0].nodeValue;

            if (j > 1 && j < 6) {
                correct = rows[j]?.getElementsByTagName("w:tc")[2]?.getElementsByTagName("w:p")[0]
                    .getElementsByTagName("w:t")[0]?.childNodes[0].nodeValue;
            }
            if (j == 6) {
                let text = ""
                const allSolnText = rows[j]?.getElementsByTagName("w:tc")[1]?.getElementsByTagName("w:p")
                for (let k = 0; k < allSolnText.length; k++) {
                    const allText = allSolnText[k]?.getElementsByTagName("w:t")
                    for (let z = 0; z < allText.length; z++) {
                        text += "\n" + allText[z]?.childNodes[0]?.nodeValue
                    }
                }
                value = text
            }
            if (j === 0) {
                makeQuestion['question'] = value
            }
            if (j == 1) {
                makeQuestion['questionType'] = 1
                makeQuestion['type'] = value
            }
            if (j === 2) {
                makeQuestion['optionA'] = value
            }
            if (j === 3) {
                makeQuestion["optionB"] = value
            }
            if (j === 4) {
                makeQuestion["optionC"] = value
            }
            if (j === 5) {
                makeQuestion["optionD"] = value;
            }
            if (j === 6) {
                makeQuestion["explanation"] = value;
            }

            if (correct == "correct") {
                makeQuestion["correctOpt"] = String.fromCharCode(62 + (j + 1));
            }
            makeQuestion["optionType"] = 1
            makeQuestion["testSeriesId"] = testSeriesId
        }
        allQuestions.push(makeQuestion)
    }
    return allQuestions;
}



const CsvParser = props => {
    const { callbackLoader, callbackQuestion, testSeriesId, errorsCallback } = props;
    const allowedHeaders = ["Question", "Option A", "Option B", "Option C", "Option D", "Correct Option", "Explanation"];
    const fileRef = useRef(null);

    const handleOnDrop = (data) => {
        callbackLoader(true)
        const file = fileRef.current?.state.file
        let questionArr = [];

        if (file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            const reader = new FileReader(file)
            reader.onload = (e) => {
                const content = e.target.result;
                const paragraphs = getParagraphs(content, testSeriesId);
                callbackQuestion(paragraphs);
                callbackLoader(false)
                return;
            };

            reader.onerror = (err) => console.error(err);

            reader.readAsBinaryString(file);
        }
        else if (file.type == "text/csv") {
            data.map(item => {
                item = item['data']
                if (item[0]) {
                    let question = {

                        question: item[0],
                        optionA: item[1],
                        optionB: item[2],
                        optionC: item[3],
                        optionD: item[4],
                        correctOpt: !isNaN(item[5]) ? String.fromCharCode(64 + parseInt(item[5])) : item[5],
                        explanation: item[6],
                        questionType: 1,
                        optionType: 1,
                        type: item[7] ?? "test",
                        testSeriesId: testSeriesId
                    }
                    questionArr.push(question);
                }
            })
            callbackQuestion(questionArr);
            callbackLoader(false)
        }
        else {
            errorsCallback("File formatting error: Please enter valid csv, xlx or docx file")
            return;
        }
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err, "file error", file, inputElem, reason)
        errorsCallback(err)
    }

    const handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
    }

    return (
        <CSVReader
            ref={fileRef}
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/vnd.ms-excel'
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            removeButtonColor='#659cef'
            onRemoveFile={handleOnRemoveFile}
        >
            <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
    )
};

export default CsvParser 
/* 
        define and export all the action types here and use them in actions as well as in reducers

        ==> export const ACTION_TYPE = "ACTION_TYPE"


*/

export const SET_USER_DETAILS= "SET_USER_DETAILS";
export const SET_AUTH_STATUS= "SET_AUTH_STATUS";
  
 
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudentCount, updateTestSeriesStatus } from "../../api/testseries";

import Snackbar from '@material-ui/core/Snackbar';
import { Parser } from '@json2csv/plainjs';
import { fetchTestSeriesQuestions } from "../../api/testseries";
import he from "he";

const SeriesListRow = props => {

    const { row, index, deleteCat, catId, setPublishModal, setSeriesDetails } = props
    const [hiddenStatus, setHiddenStatus] = useState(row.hidden);
    const [seriesId, setSeriesId] = useState(row.id);
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [attemptCount, setAttempCount] = useState(0)
    const updateStatusCallBack = (response, status) => {
        if (response.status == 200) {
            setHiddenStatus(status)
        }
        else {
            console.log(response.status)
        }
    }

    useEffect(() => {


        if (row.id) {
            getStudentCount(row.id, response => {
                if (response.status == 200) {
                    response.json().then(data => {
                        setAttempCount(data);
                    })
                }
            })
        }
    }, [row])
    const closeSnack = () => {
        setIsSnackBarShow(false)
    }
    const publishBtnHandler = () => {
        if (row.questionCount <= 0) {
            setIsSnackBarShow(true)
            setSnackBarMessage("Please Add Questions To publish Test")
        } else {
            setPublishModal(true);
            setSeriesDetails(row, publishModelCallBack, index)
        }

    }
    const publishModelCallBack = () => {
        setHiddenStatus(false)
    }

    const createCsv = (csv, fileTitle) => {
        const fileName = fileTitle + '.csv' || 'export.csv';
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        }
        else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const downloadTest = (id, name) => {
        try {
            fetchTestSeriesQuestions(id, 0, 300, (response) => {
                if (response.status == 200) {
                    response.json().then(data => {
                        let questionArr = []
                        const allData = [...data];
                        allData?.forEach(item=>{
                            delete item['question']['id']
                            delete item['question']['testSeriesId']
                            delete item['question']['createdAt']
                            questionArr.push(item['question'])
                        })
                        const parser = new Parser();
                        const csv = parser.parse(questionArr);
                        createCsv(csv, name)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <tr>
                <td align="center">{index + 1}</td>
                <td align="center">{row.title}</td>
                <td align="center">{row.timeDuration}</td>
                <td align="center">{row.maxMarks}</td>
                <td align="center">{row.questionCount}</td>
                <td align="center">{attemptCount}</td>
                <td align="center">{row.sortOrder}</td>
                <td>
                    <button type="button" class="btn btn-danger mr-1 px-2" onClick={() => deleteCat(row.id, index)}>
                        Delete
                    </button>
                    <Link to={"/addTestSeries/" + catId + "/edit/" + row.id + "/" + row.title + "/name"}>
                        <button type="button" class="btn btn-primary mr-1 px-2">
                            Edit Questions
                        </button>
                    </Link>
                    {hiddenStatus ? (
                        // <button aria-label="delete" onClick={() => updateTestSeriesStatus(false, seriesId, (response) => updateStatusCallBack(response, false))} className="btn btn-warning mr-1">
                        //     PUBLISH
                        // </button>
                        <button aria-label="delete" onClick={() => publishBtnHandler()} className="btn btn-warning mr-1">
                            PUBLISH
                        </button>
                    ) : (
                        <button aria-label="delete" onClick={() => updateTestSeriesStatus(true, seriesId, (response) => updateStatusCallBack(response, true))} className="btn btn-warning mr-1">
                            HIDE
                        </button>
                    )}
                    <button type="button" class="btn btn-success mr-1 px-2" onClick={() => downloadTest(row.id, row.title)}>
                        Download
                    </button>
                </td>
            </tr>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </>
    )
}

export default SeriesListRow